import { theme } from '@local/web-design-system';
import { Grid, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { AvatarCell } from 'src/components/AvatarGroup';
import { StringWithUsers } from 'src/components/StringWithUsers';

interface Profile {
    name: string;
    initials: string;
    color: string;
}

interface CommentData {
    user: { profile: Profile };
    name: string;
    content: string;
    date: string | 0 | undefined;
    dateTime: number | undefined;
}

export const Comments = ({
    commentsData,
    isOpen,
    propertiesPanel,
}: {
    commentsData: CommentData[];
    isOpen: boolean;
    propertiesPanel?: boolean;
}) =>
    isOpen ? (
        <>
            {commentsData
                .slice()
                .sort((a, b) => {
                    const dateA = a.dateTime ?? 0;
                    const dateB = b.dateTime ?? 0;
                    return dateB - dateA;
                })
                .map((data, index) => (
                    <Grid
                        automation-id={`comment-${index}`}
                        container
                        spacing={1}
                        key={uuidv4()}
                        sx={{ margin: 0 }}
                    >
                        <Grid
                            item
                            sx={{ paddingLeft: propertiesPanel ? '0px !important' : 'inherit' }}
                        >
                            <AvatarCell item={data.user} itemKey="profile" />
                        </Grid>
                        <Grid item container alignContent={'center'} spacing={0.5} xs>
                            <Grid item>
                                <Typography variant="body2">{data.name}</Typography>
                            </Grid>
                            <Grid item margin={`0 ${theme.spacing(1)}`}>
                                <Typography variant="body2" color="textSecondary">
                                    {data.date}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <StringWithUsers content={data.content.trim()} />
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
        </>
    ) : null;
